import { action, Action, thunk, Thunk } from "easy-peasy";
import { Injections, StoreModel } from "./model";
import {
  makeAuthorizedPostRequestToBackend,
  makeUrl
} from "../helpers/backendApi";

export interface SkykickInitialData {
  dataSource: object[];
  columns: object[];
}
export interface SkykickModel {
  NAME: string;
  INITIAL_DATA_ENDPOINT: string;
  initialData: SkykickInitialData;
  receiveInitialData: Action<SkykickModel, SkykickInitialData>;
  markInitialDataReceived: Action<SkykickModel, void>;
  maybeHandleFetchInitialData: Thunk<
    SkykickModel,
    void,
    Injections,
    StoreModel
  >;
  handleFetchInitialData: Thunk<SkykickModel, void, Injections, StoreModel>;
}

const INITIAL_QUERY = `
SELECT * 
FROM df 
-- WHERE do__OrderState = 'Cancelled' AND do__BackupChargedActivationDate IS NOT NULL
ORDER BY "y: ChurnRiskScore" DESC
`;

const skykickModel: SkykickModel = {
  INITIAL_DATA_ENDPOINT: "skykick_partners",
  NAME: "skykick",
  initialData: { dataSource: [], columns: [] },
  receiveInitialData: action((state, payload) => {
    state.initialData = payload;
  }),
  maybeHandleFetchInitialData: undefined,
  handleFetchInitialData: thunk(async (actions, payload, { getState }) => {
    const { INITIAL_DATA_ENDPOINT } = getState();

    const resp = await makeAuthorizedPostRequestToBackend({
      url: makeUrl(INITIAL_DATA_ENDPOINT),
      data: { query: INITIAL_QUERY },
      axiosConfig: undefined
    });
    actions.receiveInitialData(resp.data);
    actions.markInitialDataReceived();
  }),
  markInitialDataReceived: undefined
};

export default skykickModel;
