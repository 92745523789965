import useRedirect from "../hooks/useRedirect";
import {useMe} from "../hooks/useMe";
import routes from "./routes";

interface AuthorizationState {
  loading: boolean;
  isAuthorized: boolean;
}
interface MeData {
  loading: boolean;
  success: boolean;
  role: string;
  initialData: any;
}

const ROUTES_PATT = RegExp("^/.*$");

export function allowedRoutesForRole(role: string) {
  return ROUTES_PATT;
}

const amIAuthorized = (meData: MeData, route: string): boolean => {
  const allowedPatt = allowedRoutesForRole(meData.role);
  return allowedPatt.test(route);
};

export function useIsAuthorized(route: string): AuthorizationState {
  const me = useMe();
  const isAuthorized = me.success
    ? amIAuthorized(me.initialData, route)
    : false;
  return { loading: me.loading, isAuthorized };
}

export function useAuthorizedRoutes(): Set<string> {
  const me = useMe();
  let authorizedRoutes = new Set([]);
  if (me.success) {
    authorizedRoutes = new Set(
      Object.values(routes).filter(route =>
        amIAuthorized(me.initialData, route)
      )
    );
  }
  return authorizedRoutes;
}

export function useRedirectIfNotAuthorized(
  protectedRoute: string,
  redirectRoute: string
): boolean {
  const auth = useIsAuthorized(protectedRoute);

  const doneLoading = !auth.loading;
  const isDefinitelyNotAuthorized = doneLoading && !auth.isAuthorized;
  const isDefinitelyAuthorized = doneLoading && auth.isAuthorized;
  useRedirect(() => isDefinitelyNotAuthorized, redirectRoute);
  return isDefinitelyAuthorized;
}
