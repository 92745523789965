import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import useAuthentication from "./useAuthentication";

function isLoading(doneAuthenticating, mustSignIn, meData) {
  if (!doneAuthenticating) {
    // Still waiting to hear whether the user is signed in.
    // console.log("isLoading -- 0 -- true");
    return true;
  } else if (mustSignIn) {
    // We have confirmed that the user is NOT SIGNED IN.
    // So we're done "loading".
    // They need to be redirected to the sign in page now.
    // console.log("isLoading -- 1 -- false");
    return false;
  } else if (meData) {
    // The user is signed in. Waiting on their data from the backend /api/me endpoint..
    // console.log("isLoading -- 2 -- false");
    return false;
  } else {
    // console.log("isLoading -- 3 -- true");
    return true;
  }
}

const useMeData = () => useStoreState((s) => s.me.initialData);

const useMe = () => {
  const { doneAuthenticating, authUser } = useAuthentication();
  const userIsSignedIn = doneAuthenticating && authUser !== null;
  const userMustSignIn = doneAuthenticating && !userIsSignedIn;
  const synchronize = useStoreActions((a) => a.me.synchronizeWithFirebaseAuth);
  useEffect(() => {
    synchronize({ userIsSignedIn, userMustSignIn });
  }, [synchronize, userIsSignedIn, userMustSignIn]);
  const initialData = useStoreState((s) => s.me.initialData);
  const loading = isLoading(doneAuthenticating, userMustSignIn, initialData);
  const success = !loading && !userMustSignIn && !!initialData;
  if (success !== !(loading || userMustSignIn)) {
    throw Error("Uh oh!");
  }
  return { loading, success, initialData };
};

export { useMe, useMeData };
