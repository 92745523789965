import { reducer, thunk } from "easy-peasy";
import { fbReducer } from "../fbase";
import { getFirebase } from "react-redux-firebase";

const firebaseModel = {
  red: reducer(fbReducer),
  logout: thunk(async (actions, payload, { getState }) => {
    await getFirebase().logout();
  }),
  login: thunk(async (actions, payload, { getState }) => {
    const fb = getFirebase();

    const provider = new fb.auth.GoogleAuthProvider();
    provider.addScope("email");
    provider.addScope("profile");

    await fb.auth().setPersistence(fb.auth.Auth.Persistence.LOCAL);

    await fb.auth().signInWithRedirect(provider);
  }),
};

export { firebaseModel };
