import React, { Suspense } from "react";
import { BackTop, Spin } from "antd";
import routes from "./helpers/routes";
import { Redirect, Route } from "react-router";
import useMobileDetect from "use-mobile-detect-hook";

const LazyHome = React.lazy(() => import("./Home"));
const Home = () => (
  <Suspense fallback={<Spin />}>
    <LazyHome />
  </Suspense>
);
const redirectToDefaultPage = () => <Redirect to={routes.DEFAULT} />;

function useIsMobile() {
  const detectMobile = useMobileDetect();
  return detectMobile.isMobile();
}

function LandingPage() {
  const isMobile = useIsMobile();
  return (
    <>
      <BackTop />
      <Home isMobile={isMobile} />
    </>
  );
}

export const AllRoutes = [
  <Route
    exact
    path={routes.landing}
    component={LandingPage}
    key={routes.landing}
  />,
  <Route render={redirectToDefaultPage} key="default_route" />
];
