import axios from "axios";
import { getFirebase } from "react-redux-firebase";

const getCurrentUserIdToken = async () =>
  getFirebase().auth().currentUser &&
  (await getFirebase().auth().currentUser.getIdToken(false));

// TODO: Re-enable so API calls actually work in production
console.log("process.env.NODE_ENV", process.env.NODE_ENV);
const baseUrl =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api"
    : "/api";
// const baseUrl = "http://localhost:5000/api";

export function makeUrl(endpoint) {
  return `${baseUrl}/${endpoint}`;
}

async function getIdTokenHeaders() {
  const idToken = await getCurrentUserIdToken();
  return {
    "X-ID-TOKEN": idToken,
  };
}

export async function makeAuthorizedGetRequestToBackend({ url, axiosConfig }) {
  return await makeAuthorizedRequestToBackend({
    method: "get",
    url,
    axiosConfig,
  });
}

export async function makeAuthorizedPatchRequestToBackend({
  url,
  data,
  axiosConfig,
}) {
  return await makeAuthorizedRequestToBackend({
    method: "patch",
    data,
    url,
    axiosConfig,
  });
}

export async function makeAuthorizedPostRequestToBackend({
  url,
  data,
  axiosConfig,
}) {
  return await makeAuthorizedRequestToBackend({
    method: "post",
    data,
    url,
    axiosConfig,
  });
}

export async function makePostRequestToBackend({ url, data, axiosConfig }) {
  return await makeRequestToBackend({
    method: "post",
    data,
    url,
    axiosConfig,
  });
}

export async function makeAuthorizedDeleteRequestToBackend({
  url,
  axiosConfig,
}) {
  return await makeAuthorizedRequestToBackend({
    method: "delete",
    url,
    axiosConfig,
  });
}

async function makeAuthorizedRequestToBackend({
  method,
  url,
  axiosConfig = {},
  data,
}) {
  const axiosConfigHeaders = axiosConfig.headers || {};
  const idTokenHeaders = await getIdTokenHeaders();
  const headers = { ...axiosConfigHeaders, ...idTokenHeaders };
  return await axios.request({ url, method, data, ...axiosConfig, headers });
}

async function makeRequestToBackend({ method, url, axiosConfig = {}, data }) {
  const axiosConfigHeaders = axiosConfig.headers || {};
  const headers = { ...axiosConfigHeaders };
  return await axios.request({ url, method, data, ...axiosConfig, headers });
}
