import { Thunk } from "easy-peasy";
import { actions } from "./commonActions";
import { thunks } from "./commonThunks";
import { firebaseModel } from "./firebaseModel";
import meModel, { MeModel } from "./meModel";
import speechifyModel, { SpeechifyModel } from "./speechifyModel";
import skykickModel, { SkykickModel } from "./skykickModel";

export interface Injections {}

export interface FirebaseModel {
  red: any;
  login: Thunk<FirebaseModel, void, Injections, StoreModel>;
  logout: Thunk<FirebaseModel, void, Injections, StoreModel>;
}

// The interface representing our entire store model
export interface StoreModel {
  me: MeModel;
  speechify: SpeechifyModel;
  skykick: SkykickModel;
  firebase: FirebaseModel;
}

const model: StoreModel = {
  me: meModel,
  speechify: speechifyModel,
  skykick: skykickModel,
  firebase: firebaseModel
};

Object.entries(model).forEach(([modelSliceName, modelSlice]) => {
  if (modelSliceName === "firebase") {
    return;
  }

  modelSlice.initialDataReceived = false;
  modelSlice.initialDataLoading = false;
  modelSlice.loading = {};
  modelSlice.received = {};

  const myActions = actions();
  [
    "markInitialDataReceived",
    "markInitialDataNotReceived",
    "markInitialDataLoading",
    "markInitialDataNotLoading",
    "receiveInitialData",
    "markLoading",
    "markNotLoading",
    "markReceived",
    "markNotReceived",
    "clear"
  ].forEach(actionName => {
    if (!modelSlice[actionName]) {
      modelSlice[actionName] = myActions[actionName];
    }
  });

  const myThunks = thunks(modelSliceName);
  ["handleFetchInitialData", "maybeHandleFetchInitialData"].forEach(
    thunkName => {
      if (!modelSlice[thunkName]) {
        modelSlice[thunkName] = myThunks[thunkName];
      }
    }
  );
});

export { model };
