import { action, Action, thunk, Thunk } from "easy-peasy";
import { Injections, StoreModel } from "./model";
import {
  makeAuthorizedPostRequestToBackend,
  makeUrl
} from "../helpers/backendApi";

export interface SpeechifyInitialData {
  dataSource: object[];
  columns: object[];
}
export interface SpeechifyModel {
  NAME: string;
  INITIAL_DATA_ENDPOINT: string;
  initialData: SpeechifyInitialData;
  receiveInitialData: Action<SpeechifyModel, SpeechifyInitialData>;
  markInitialDataReceived: Action<SpeechifyModel, void>;
  maybeHandleFetchInitialData: Thunk<
    SpeechifyModel,
    void,
    Injections,
    StoreModel
  >;
  handleFetchInitialData: Thunk<SpeechifyModel, void, Injections, StoreModel>;
}

// const speechifyModel: SpeechifyModel = {
//   INITIAL_DATA_ENDPOINT: "speechify_churn_data",
//   NAME: "speechify",
//   initialData: null,
//   receiveInitialData: action(actionFunctions().receiveInitialData),
//   maybeHandleFetchInitialData: undefined
// };

const speechifyModel: SpeechifyModel = {
  INITIAL_DATA_ENDPOINT: "speechify_churn_data",
  NAME: "speechify",
  initialData: { dataSource: [], columns: [] },
  receiveInitialData: action((state, payload) => {
    state.initialData = payload;
  }),
  maybeHandleFetchInitialData: undefined,
  handleFetchInitialData: thunk(async (actions, payload, { getState }) => {
    const { INITIAL_DATA_ENDPOINT } = getState();

    const resp = await makeAuthorizedPostRequestToBackend({
      url: makeUrl(INITIAL_DATA_ENDPOINT),
      data: { query: null },
      axiosConfig: undefined
    });
    actions.receiveInitialData(resp.data);
    actions.markInitialDataReceived();
  }),
  markInitialDataReceived: undefined
};

export default speechifyModel;
