// This must be the first line in src/index.js
import "./polyfills";
import "./disable-console-log-in-production.js";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { epStore } from "./store";
import { StoreProvider } from "easy-peasy";

ReactDOM.render(
  <StoreProvider store={epStore}>
    <App />
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
