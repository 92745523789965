import { message } from "antd";
import { Action, action, Thunk, thunk } from "easy-peasy";
import {
  makeAuthorizedPatchRequestToBackend,
  makeUrl
} from "../helpers/backendApi";
import { actionFunctions } from "./commonActions";
import { Injections, StoreModel } from "./model";

// The interface representing our Todos model
export interface MeModel {
  NAME: string;
  INITIAL_DATA_ENDPOINT: string;
  initialData: { id: number; timezone: string } | null;
  initialDataReceived: boolean;
  synchronizeWithFirebaseAuth: Thunk<MeModel, any, Injections, StoreModel>;
  clear: Action<MeModel, void>;
  handleUpdateTimezone: Thunk<MeModel, any, Injections, StoreModel>;
  receiveTimezone: Action<MeModel, any>;
  receiveInitialData: Action<MeModel, any>;
  maybeHandleFetchInitialData: Thunk<MeModel, any, Injections, StoreModel>;
}

// @ts-ignore
const meModel: MeModel = {
  INITIAL_DATA_ENDPOINT: "me",
  NAME: "me",
  initialData: null,
  synchronizeWithFirebaseAuth: thunk(
    async (
      actions,
      { userIsSignedIn, userMustSignIn },
      { getState, dispatch, getStoreState }
    ) => {
      const { initialDataReceived } = getState();
      if (userMustSignIn) {
        actions.clear();
      } else if (userIsSignedIn && !initialDataReceived) {
        const { email } = getStoreState().firebase.red.firebase.auth;

        try {
          await dispatch.me.maybeHandleFetchInitialData();
        } catch (err) {
          message.error(
            `Your user account (${email}) is missing or inactive.`
          );
          throw err;
        }
      }
    }
  ),
  clear: action(state => {
    state.initialData = null;
    state.initialDataReceived = false;
  }),
  handleUpdateTimezone: thunk(async (actions, payload, { getState }) => {
    const { id } = getState().initialData;
    const url = makeUrl(`users/${id}`);
    const data = { timezone: payload };
    const resp = await makeAuthorizedPatchRequestToBackend({
      url,
      data,
      axiosConfig: undefined
    });
    actions.receiveTimezone(resp.data);
  }),
  receiveTimezone: action((state, payload) => {
    state.initialData.timezone = payload.timezone;
  }),
  receiveInitialData: action(actionFunctions().receiveInitialData)
};

export default meModel;
