import firebase from "firebase/app";
import "firebase/auth";
import appConfig from "../helpers/appConfig";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
const firebaseConfig = appConfig.firebase;

firebase.initializeApp(firebaseConfig);
const fbReducer = combineReducers({
  firebase: firebaseReducer,
});

export { fbReducer, firebase };
