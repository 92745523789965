export default {
  firebase: {
    apiKey: "AIzaSyCqH7BH-L9-9J6TDSqtmiSDg9jbct4ZfiM",
    authDomain: "customax.firebaseapp.com",
    databaseURL: "https://customax.firebaseio.com",
    projectId: "customax",
    storageBucket: "customax.appspot.com",
    messagingSenderId: "483098164385",
    appId: "1:483098164385:web:76b78241ae91a11b"
  }
};
