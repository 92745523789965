import { action } from "easy-peasy";
import _ from "lodash";

export const actionFunctions = () => ({
  receiveInitialData: (state, payload) => {
    if (_.isObject(state.initialData)) {
      state.initialData = { ...state.initialData, ...payload };
    } else {
      state.initialData = payload;
    }
    Object.keys(payload).forEach((dataSliceId) => {
      state.received[dataSliceId] = true;
    });
  },
  receiveInitialDataSlice: (state, payload) => {
    state.initialData = { ...state.initialData, ...payload };
  },
});

export const actions = () => ({
  clear: action((state) => {
    state.initialData = state.nullData;
    state.initialDataReceived = false;
  }),
  receiveTable: action((state, [tableName, table]) => {
    state.initialData[tableName] = table;
  }),
  receiveInitialDataSlice: action(actionFunctions().receiveInitialDataSlice),
  markLoading: action((state, dataSliceId) => {
    state.loading[dataSliceId] = true;
  }),
  markNotLoading: action((state, dataSliceId) => {
    state.loading[dataSliceId] = false;
  }),
  markReceived: action((state, dataSliceId) => {
    state.received[dataSliceId] = true;
  }),
  markNotReceived: action((state, dataSliceId) => {
    state.received[dataSliceId] = false;
  }),
  markInitialDataReceived: action((state) => {
    state.initialDataReceived = true;
  }),
  markInitialDataNotReceived: action((state) => {
    state.initialDataReceived = false;
  }),
  markInitialDataLoading: action((state) => {
    state.initialDataLoading = true;
  }),
  markInitialDataNotLoading: action((state) => {
    state.initialDataLoading = false;
  }),
});
