import React, { useCallback, useMemo } from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Col, Layout, Menu, Row } from "antd";
import useTitle from "react-use/lib/useTitle";
import { useAuthorizedRoutes } from "../helpers/useAuthorization";
import routes from "../helpers/routes";
import styled from "styled-components/macro";
import {
  LAYOUT_CONTENT_HORIZONTAL_PADDING,
  LAYOUT_CONTENT_VERTICAL_PADDING,
  LAYOUT_HEADER_HEIGHT_PX,
  LAYOUT_LEFTNAV_ITEM_HEIGHT,
  LAYOUT_LEFTNAV_WIDTH_PX,
} from "../styles/constants";
import { Colors, H3 } from "@blueprintjs/core";
import { useHistory, useLocation } from "react-router";

const { Header, Content, Sider } = Layout;

export const leftNavMenuItems = [
  {
    key: routes.skykick_partners,
    title: "Tables",
    iconType: "table",
  },
  {
    key: routes.churn_skykick,
    title: "Charts",
    iconType: "bar-chart",
  },
  {
    key: "/settings",
    title: "Settings",
    iconType: "setting",
  },
  {
    key: routes.profile,
    title: "Profile",
    iconType: "user",
  },
];

const StyledSider = styled(Sider)`
  && {
    margin: 0;
    padding: 0;
    width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    min-width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    max-width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    background: white;
  }
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;

  && .ant-menu-item.ant-menu-item.ant-menu-item {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${LAYOUT_LEFTNAV_ITEM_HEIGHT} !important;
    line-height: ${LAYOUT_LEFTNAV_ITEM_HEIGHT} !important;
  }
  && .anticon {
    height: 16px;
    line-height: 16px;
    vertical-align: middle;
    color: rgb(255, 255, 255, 0.8);
  }
`;

function LeftNavSider() {
  const location = useLocation();

  const authorizedRoutes = useAuthorizedRoutes();
  // noinspection JSCheckFunctionSignatures
  const authorizedLeftNavMenuItems = useMemo(
    () =>
      leftNavMenuItems.filter(({ urlPath }) => authorizedRoutes.has(urlPath)),
    [authorizedRoutes]
  );

  return (
    <StyledSider collapsed={true}>
      <StyledMenu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[location.pathname]}
      >
        {authorizedLeftNavMenuItems.map(LeftNavMenuItem)}
      </StyledMenu>
    </StyledSider>
  );
}

function LeftNavMenuItem({ urlPath, title, iconType }) {
  const history = useHistory();
  const onClick = useCallback(() => history.push(urlPath), [history, urlPath]);
  return (
    <Menu.Item
      key={urlPath}
      title={title}
      onClick={onClick}
      data-testid={`leftnav-${urlPath}`}
    >
      <LegacyIcon type={iconType} />
    </Menu.Item>
  );
}

function MyHeader({ headerText }) {
  return (
    <Header
      data-testid="wrapper_header"
      css={`
        height: ${LAYOUT_HEADER_HEIGHT_PX};
        vertical-align: center;
      `}
    >
      <Row
        type="flex"
        justify="start"
        align="middle"
        css={`
          height: 100%;
          margin: 0 -36px 0 -24px;
        `}
      >
        <Col>
          <H3
            css={`
              padding: 0 !important;
              margin: 0 !important;
            `}
          >
            {headerText}
          </H3>
        </Col>
      </Row>
    </Header>
  );
}

const getStyledContentWidth = ({ hideLeftNav }) =>
  hideLeftNav ? "auto" : `100%`;

function StyledContent({ hideLeftNav, children, ...restProps }) {
  return (
    <Content
      {...restProps}
      css={`
        background-color: ${Colors.DARK_GRAY3};
        padding: ${LAYOUT_CONTENT_VERTICAL_PADDING}
          ${LAYOUT_CONTENT_HORIZONTAL_PADDING};
        margin: 0;
        min-height: 280px;
        height: calc(100vh - ${LAYOUT_HEADER_HEIGHT_PX});
        width: ${getStyledContentWidth};
        display: flex;
        justify-content: center;
      `}
    >
      {children}
    </Content>
  );
}

const AppWrapper = ({ headerText, hideLeftNav, children }) => {
  useTitle(headerText);

  return (
    <Layout
      css={`
        min-height: 100vh;
      `}
    >
      <MyHeader headerText={headerText} />
      <Layout>
        {hideLeftNav ? null : <LeftNavSider />}
        <Layout>
          <StyledContent hideLeftNav={hideLeftNav}>{children}</StyledContent>{" "}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppWrapper;
