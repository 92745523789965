export default {
  churn_demo: "/churn/demo",
  churn_speechify: "/churn/speechify",
  churn_skykick: "/skykick/partner_churn",
  skykick_partners: "/skykick/partners",
  landing: "/welcome",
  signin: "/signin",
  profile: "/profile",
  root: "/",
  DEFAULT: "/welcome"
};
